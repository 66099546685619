body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
*{
  box-sizing: border-box;
}
button, div, span, input, button, select, textarea{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

@keyframes loading-rotation {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}