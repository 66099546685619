.fade-in{
  animation: fade-in 300ms;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from{
    opacity: 0
  }
  to{
    opacity: 1
  }
}

.fade-out{
  animation: fade-out 300ms;
  animation-fill-mode: forwards;
}

@keyframes fade-out {
  from{
    opacity: 1
  }
  to{
    opacity: 0
  }
}

.pop-in-left{
  animation: pop-in-left 200ms;
}

@keyframes pop-in-left {
  0%{
    opacity: 0;
    transform: scale(.85) translateX(-60px);
  }
  40%{
    opacity: 1
  }
  100%{
    transform: scale(1) translateX(0px);
  }
}

.pop-out-left{
  animation: pop-out-left 200ms;
}

@keyframes pop-out-left {
  0%{
    opacity: 1;
    transform: scale(1) translateX(0px);
  }
  40%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    transform: scale(.85) translateX(-60px);
  }
}

.pop-in-left-down{
  animation: pop-in-left-down 200ms;
}

@keyframes pop-in-left-down {
  0%{
    opacity: 0;
    transform: scale(.85) translateX(-60px) translateY(-60px);
  }
  40%{
    opacity: 1
  }
  100%{
    transform: scale(1) translateX(0px) translateY(0px);
  }
}

.pop-out-left-up{
  animation: pop-out-left-up 200ms;
}

@keyframes pop-out-left-up {
  0%{
    opacity: 1;
    transform: scale(1) translateX(0px) translateY(0px);
  }
  40%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    transform: scale(.85) translateX(-60px) translateY(-60px);
  }
}

.slide-in-bottom{
  animation: slide-in-bottom 400ms;
  animation-fill-mode: forwards;
}

@keyframes slide-in-bottom {
  from {
    transform: translateY(110%);
  }
  to{
    transform:translateY(0%)
  }
}

.slide-out-bottom{
  animation: slide-out-bottom 400ms;
  animation-fill-mode: forwards;
}

@keyframes slide-out-bottom {
  from {
    transform: translateY(0%);
  }
  to{
    transform:translateY(110%)
  }
}

.slide-in-right{
  animation: slide-in-right 400ms;
  animation-fill-mode: forwards;
}

@keyframes slide-in-right {
  from{
    transform: translateX(110%);
  }
  to{
    transform: translateX(0%);
  }
}


.slide-out-right{
  animation: slide-out-right 400ms;
  animation-fill-mode: forwards;
}

@keyframes slide-out-right {
  from{
    transform: translateX(0%);
  }
  to{
    transform: translateX(110%);
  }
}

.ripple{
  animation: ripple 300ms;
  animation-fill-mode: forwards;
}

@keyframes ripple {
  0%{
    width: 0px;
    transform: translate(-50%, -50%) scale(0);
  }
  50%{
    opacity: 1;
  }
  100%{
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.document-date-input{
  background: red;
}

.pop-in-down{
  animation: pop-in-down 200ms;
}

@keyframes pop-in-down {
  0%{
    opacity: 0;
    transform: scale(.95) translateY(-60px);
  }
  40%{
    opacity: 1
  }
  100%{
    transform: scale(1) translateY(0px);
  }
}

.pop-out-up{
  animation: pop-out-up 200ms;
}

@keyframes pop-out-up {
  0%{
    opacity: 1;
    transform: scale(1) translateY(0px);
  }
  40%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    transform: scale(.85) translateY(-60px);
  }
}

input:-webkit-autofill {
    /* animation-name: onAutoFillStart; */
    transition: background-color 50000s ease-in-out 0s;
}

.explode-out{
  animation: explode-out 800ms;
  animation-fill-mode: forwards;
}

@keyframes explode-out {
  0%{
    transform: scale(1);
  }
  30%{
    transform: scale(1.8);
    opacity: 1;
  }
  60%{
    opacity: 0;
  }
  100%{
    transform: scale(0);
    opacity: 0;
    display: none;
  }
}

.pulse{
  animation: pulse 800ms;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.2);
  }
  100%{
    transform: scale(1);
  }
}

.loading-button {
  animation: pulse 20s;
  animation-iteration-count: infinite;
}

@keyframes loading-rotation {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}
